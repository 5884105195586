import { styled } from '@linaria/react';

export const Form = styled.form`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--spacing-sm);
`;

export const Item = styled.div<{ size: 'small' | 'large' }>`
  width: ${(props) => (props.size === 'small' ? '88px' : '100%')};
`;
