import { RMButton } from '@/components/RMButton/RMButton.js';
import { RMInput } from '@/components/RMInput/RMInput.js';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport.js';
import { InputContainer } from '@/modules/form/container.js';
import { useIsFormValid } from '@/modules/form/form.js';

import { PersonInviteByEmailForm } from '../../form/person-invite-by-email.form.js';

import { Form, Item } from './ProjectInviteByEmail.styles.js';

interface PersonInviteByEmailProps {
  form: PersonInviteByEmailForm;
  onSubmit: () => void;
}

export function ProjectInviteByEmail({ form, onSubmit }: PersonInviteByEmailProps) {
  const isValid = useIsFormValid(form);
  const isMobile = useIsMobileViewport();

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Item size="large">
        <InputContainer form={form} path="email">
          {(props) => (
            <RMInput
              id="invite-dialog-email-field"
              type="email"
              placeholder="Enter email address"
              autoFocus={!isMobile}
              {...props}
              error={null}
            />
          )}
        </InputContainer>
      </Item>
      <Item size="small">
        <RMButton fullWidth background="primary" autoLoading disabled={!isValid} onClick={onSubmit}>
          Invite
        </RMButton>
      </Item>
    </Form>
  );
}
