import EmailValidator from 'email-validator';
import { z } from 'zod';

import { Form } from '@/modules/form/@types.js';
import { DeepPartial } from '@/modules/form/@types-utils.js';
import { createForm } from '@/modules/form/form.js';

export function createPersonInviteByEmailFormSchema() {
  return z.object({
    email: z.string().refine(EmailValidator.validate),
  });
}

export type PersonInviteByEmailFormInput = z.input<ReturnType<typeof createPersonInviteByEmailFormSchema>>;
export type PersonInviteByEmailFormOutput = z.output<ReturnType<typeof createPersonInviteByEmailFormSchema>>;

export type PersonInviteByEmailForm = Form<PersonInviteByEmailFormInput, PersonInviteByEmailFormOutput>;

export function createPersonInviteByEmailForm(
  defaultValues?: DeepPartial<PersonInviteByEmailFormInput>,
): PersonInviteByEmailForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createPersonInviteByEmailFormSchema(),
  });
}
