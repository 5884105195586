import { PropsWithChildren } from 'react';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport.js';

import { List } from './ProjectShareMemberListRoot.styles.js';

export function ProjectShareMemberListRoot({ children }: PropsWithChildren<unknown>) {
  const mobile = useIsMobileViewport();

  return <List mobile={mobile}>{children}</List>;
}
