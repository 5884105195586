import { styled } from '@linaria/react';

export const MemberRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-sm);
`;

export const MemberRole = styled.div`
  flex-shrink: 0;
  padding-right: var(--spacing-lg);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: auto;
`;

export const MemberAvatar = styled.div`
  flex-shrink: 0;
`;

export const MemberInfo = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  gap: var(--spacing-3xs);
  overflow: hidden;
  min-width: 0;

  > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const MenuItemTrigger = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-2xs);
`;

export const ContextMenuItemSeparator = styled.div`
  width: auto;
  height: 1px;
  margin: 0.25rem 1rem;
  border-bottom: 1px dashed #d5d0c3;
  box-sizing: border-box;
  display: block;
`;
