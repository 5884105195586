import { notNull } from '@remento/utils/array/notNull';

import { RMAvatarSharing } from '@/components/RMAvatarSharing';
import { RMContextMenuSelect } from '@/components/RMContextMenuSelect/RMContextMenuSelect';
import { RMText } from '@/components/RMText/RMText';

import { MemberAvatar, MemberInfo, MemberRole, MemberRow } from './ProjectShareMemberListItem.styles';

interface ProjectShareMemberListItemProps {
  initials: string;
  photoUrl: string | null;
  name: string;
  email: string;
  readOnly: boolean;
  displayLabel: string;
  avatarColor?: string | null;
  pending?: boolean;
  onResendInvite?: () => void;
  onRemove?: () => void;
}

export function ProjectShareMemberListItem({
  initials,
  photoUrl,
  name,
  email,
  readOnly,
  displayLabel,
  avatarColor,
  pending,
  onResendInvite,
  onRemove,
}: ProjectShareMemberListItemProps) {
  return (
    <MemberRow>
      <MemberAvatar>
        <RMAvatarSharing.Item
          backgroundColor={avatarColor ?? 'transparent'}
          imageURL={photoUrl}
          text={initials}
          border={pending ? 'dashed' : 'none'}
        />
      </MemberAvatar>
      <MemberInfo>
        <RMText type="sans" size="xs" color={pending ? 'on-surface-tertiary' : 'inverse-on-primary'} bold="medium">
          {pending ? email : name}
        </RMText>

        <RMText type="sans" size="xxs" color="on-surface-tertiary">
          {pending ? 'Pending' : email}
        </RMText>
      </MemberInfo>
      <MemberRole>
        {readOnly == false ? (
          <RMContextMenuSelect
            value={displayLabel}
            options={[
              {
                label: displayLabel,
                value: displayLabel,
              },
            ]}
            actions={[
              pending
                ? ({
                    id: 'resend',
                    label: 'Resend invite',
                    onSelect: () => onResendInvite?.(),
                  } as const)
                : null,
              {
                id: 'remove',
                label: 'Remove',
                color: 'danger',
                onSelect: () => onRemove?.(),
              } as const,
            ].filter(notNull)}
          ></RMContextMenuSelect>
        ) : (
          <RMText type="sans" size="xs" color="on-surface-tertiary" bold="medium">
            {displayLabel}
          </RMText>
        )}
      </MemberRole>
    </MemberRow>
  );
}
