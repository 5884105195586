import { styled } from '@linaria/react';

export const List = styled.div<{
  mobile?: boolean;
}>`
  display: flex;
  flex-flow: column;
  gap: var(--spacing-md);

  max-height: ${({ mobile }) => (mobile ? 'unset' : '20rem')};
  overflow-y: ${({ mobile }) => (mobile ? 'unset' : 'auto')};

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar);
  }
`;
